<template>
  <atoms-c-modal
    class="email_verify_modal"
    :class="props.type"
    is-open
    @close="closeModal"
  >
    <template #title>
      <h2>{{ title }}</h2>
    </template>
    <template #body>
      <!-- eslint-disable-next-line -->
      <p v-html="descText"></p>
      <modules-input-email
        v-show="phase === EMAIL_READY"
        id="email"
        ref="email"
        type="findPwd"
      />
      <modules-input-email-code
        v-show="phase > EMAIL_READY"
        id="emailCode"
        ref="emailCode"
        :user-id="props.userId"
        :email="email?.emailValue"
        :send-status="email?.sendStatus"
        hide-btn
        placeholder="인증번호를 입력해주세요."
        @set-send-status="email?.setSendStatus"
      />
      <template v-if="email">
        <p v-if="email.emailValidStateMap.err" class="txt_err">
          {{ email.emailValidStateMap.msg }}
        </p>
      </template>
      <template v-if="emailCode">
        <p v-if="emailCode.emailCodeValidStateMap.err" class="txt_err">
          {{ emailCode.emailCodeValidStateMap.msg }}
        </p>
      </template>
    </template>
    <template #footer>
      <atoms-outlined-button
        v-if="phase > EMAIL_READY"
        class="btn_send"
        text="이메일 다시 받기"
        :disabled="phase === EMAIL_SENDED"
        @click="sendEmail"
      />
      <atoms-default-button
        class="btn_next"
        :text="btnText"
        :disabled="nextDisabled"
        @click="handleClick"
      />
    </template>
  </atoms-c-modal>
</template>
<script lang="ts" setup>
import { EMAIL_READY, EMAIL_SENDED } from '@/utils/constants'
import type {
  ModulesInputEmail,
  ModulesInputEmailCode
} from '#build/components'

const props = defineProps<{ type: 'password' | 'unlock'; userId: string }>()
const { openAlertModal } = useModalStore()

const email = ref<InstanceType<typeof ModulesInputEmail>>()
const emailCode = ref<InstanceType<typeof ModulesInputEmailCode>>()
const phase = ref(0)

const title = computed(() => {
  // if (phase.value === EMAIL_READY) {
  if (props.type === 'unlock') {
    return '이메일로 로그인 제한 해제하기'
  } else if (props.type === 'password') {
    return '비밀번호 찾기'
  }
  //}
  //return '이메일 인증'
})
const descText = computed(() => {
  if (phase.value === EMAIL_READY) {
    if (props.type === 'unlock') {
      return '회원정보에 등록한 이메일으로 인증번호를 발송해드립니다.'
    }
    return `회원 정보에 등록된 이메일로<br class="mo_only" /> 본인 인증을 완료해주세요.`
  }
  return '메일로 발송된 인증번호를 입력해<br class="mo_only" /> 본인 인증을 완료해주세요.'
})
const btnText = computed(() => {
  return phase.value === EMAIL_READY ? '이메일 발송' : '인증하기'
})

const nextDisabled = computed(() => {
  return (
    (phase.value === EMAIL_READY &&
      email.value &&
      email.value.emailValidStateMap.valid === false) ||
    (phase.value !== 0 && emailCode.value && emailCode.value.disabled)
  )
})

watch(
  () => email.value?.sendStatus,
  (newVal) => {
    if (newVal && Number.isInteger(newVal)) {
      phase.value = newVal
    }
  }
)
watch(
  () => emailCode.value?.emailVerifyToken,
  (newVal) => {
    if (newVal && newVal.length > 0) {
      closeModal()
      emit('update:pwdToken', emailCode.value?.emailVerifyToken)
    }
  }
)

// 인증 완료 시 모달 닫기
watch(
  () => emailCode.value?.isMatchedCode,
  (newVal) => {
    if (newVal && props.type === 'unlock') {
      closeModal()
      openAlertModal({
        title: '인증 완료',
        content: '로그인 제한이 해제되었습니다.\n다시 로그인을 시도해주세요.'
      })
    }
  }
)

const emit = defineEmits(['close', 'update:pwdToken'])

const handleClick = async () => {
  if (phase.value === EMAIL_READY) {
    sendEmail()
  } else if (phase.value !== EMAIL_READY) {
    verifyCode()
  }
}

const sendEmail = () => {
  if (
    props.type === 'unlock' &&
    typeof email?.value?.sendCodeForUnlockAccount === 'function'
  ) {
    email?.value?.sendCodeForUnlockAccount(props.userId)
  } else if (
    props.type === 'password' &&
    typeof email?.value?.findPassword === 'function'
  ) {
    email.value.findPassword()
  }
}

const verifyCode = () => {
  if (typeof emailCode?.value?.verifyCode === 'function') {
    emailCode.value.verifyCode(props.type)
  }
}

const closeModal = () => {
  emit('close')
}
</script>
<style lang="scss" scoped>
.email_verify_modal {
  &.unlock {
    @include desktop() {
      ::v-deep(.content_modal) {
        width: 480px;
      }
    }
  }

  ::v-deep(.body_modal) {
    > p {
      font-size: 18px;
      @include tablet {
        font-size: 15px;
      }
      @include mobile {
        > span {
          display: inline-block;
        }
      }
      &.txt_err {
        margin-top: 8px;
        color: $sys-red0;
        font-size: 16px;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.64px;
        text-align: left;
        @include tablet {
          font-size: 15px;
        }
      }
    }

    > .input_form {
      margin-top: 16px;
    }
  }
}
.btn {
  flex: 1;
  min-width: 136px;
}
.btn_send {
  padding: 12px 14px;
  ::v-deep(.txt_btn) {
    font-size: 18px;
  }
}
</style>
