<template>
  <atoms-c-modal class="find_id_modal" is-open @close="closeModal">
    <template #title>
      <h2>아이디 찾기</h2>
    </template>
    <template #body>
      <!--prettier-ignore -->
      <p>
        회원 정보에 등록된 이메일로<br class="mo_only" /> 아이디를 발송해드립니다.
      </p>
      <modules-input-email id="email" ref="email" type="findId" />
      <template v-if="email">
        <p v-if="email.emailValidStateMap.err" class="txt_err">
          {{ email.emailValidStateMap.msg }}
        </p>
      </template>
    </template>
    <template #footer>
      <atoms-default-button
        class="btn_next"
        text="이메일 발송"
        :disabled="email?.emailValidStateMap.valid === false"
        @click="handleClick"
      />
    </template>
  </atoms-c-modal>
</template>
<script lang="ts" setup>
const props = defineProps({ isOpen: { type: Boolean, default: false } })
import type { ModulesInputEmail } from '#build/components'

const email = ref<InstanceType<typeof ModulesInputEmail>>()
const { openAlertModal } = useModalStore()

const emit = defineEmits(['close'])

const handleClick = async () => {
  if (typeof email?.value?.findUserId === 'function') {
    const rvCode = await email.value.findUserId()
    if (rvCode === 0) {
      openAlertModal({
        title: '발송 완료',
        content: '등록된 이메일로 아이디 정보를 발송했습니다.'
      })
      emit('close')
    }
  }

  // 이메일 발송
  //phase.value = 1
}
const closeModal = () => {
  emit('close')
}
</script>
<style lang="scss" scoped>
.find_id_modal {
  ::v-deep(.body_modal) {
    > p {
      font-size: 18px;
      @include tablet {
        font-size: 15px;
      }
      @include mobile {
        > span {
          display: inline-block;
        }
      }
      &.txt_err {
        margin-top: 8px;
        color: $sys-red0;
        font-size: 16px;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.64px;
        text-align: left;
        @include tablet {
          font-size: 15px;
        }
      }
    }

    > .input_form {
      margin-top: 16px;
    }
  }
}
.btn_next {
  flex: 1;
}
</style>
