<template>
  <template-login-bg-login class="page_login">
    <template-common-form
      v-if="phase === DEFAULT_PHASE"
      class="form_login"
      title-txt="로그인"
    >
      <div class="row_form">
        <label class="txt_form" for="userId">아이디</label>

        <atoms-c-input
          id="userId"
          v-model="userId"
          class="input_form"
          autocomplete="username"
          placeholder="아이디를 입력해주세요"
          required
        />
      </div>
      <div class="row_form">
        <label class="txt_form" for="pwd">비밀번호</label>

        <atoms-c-input
          id="pwd"
          v-model="pwd"
          class="input_form"
          :type="pwdType"
          autocomplete="new-password"
          placeholder="비밀번호를 입력해주세요."
          required
        >
          <atoms-c-icon
            class="eye_pwd"
            :icon-name="pwdIconName"
            @click="() => (isHide = !isHide)"
          />
        </atoms-c-input>
      </div>
      <p v-if="hasError" class="txt_err">
        <atoms-c-icon ID icon-name="fail" />
        <span>ID 또는 비밀번호를 잘못 입력했거나 등록되지 않은 ID 입니다.</span>
      </p>
      <hr />
      <div class="wrap_btn">
        <atoms-default-button
          class="btn_login"
          text="로그인"
          :disabled="disabled"
          @click="handleLogin"
        ></atoms-default-button>
      </div>
      <ul class="list_option">
        <li
          v-for="(item, idx) in options"
          :key="idx"
          class="item_option"
          @click="handleOption(item.type)"
        >
          {{ item.text }}
        </li>
      </ul>
    </template-common-form>
    <template-common-setting-password
      v-else-if="phase === PWD_PHASE"
      v-model:pwdToken="pwdToken"
    />

    <Teleport v-if="isOpenFindId" to="#modals">
      <template-login-find-id-modal @close="closeModal" />
    </Teleport>
    <Teleport v-if="isOpenFindPwd || isOpenUnlock" to="#modals">
      <template-login-email-verify-modal
        v-model:pwdToken="pwdToken"
        :type="isOpenFindPwd ? 'password' : 'unlock'"
        :user-id="userId"
        @close="closeModal"
      />
    </Teleport>
  </template-login-bg-login>
</template>
<script lang="ts" setup>
import { DEFAULT_PHASE, PWD_PHASE, TERMS_PHASE } from '@/utils/constants'
const TYPE_FIND_ID = 'find-id'
const TYPE_FIND_PWD = 'find-pwd'
const TYPE_UNLOCK = 'unlock'
useCustomMeta('로그인')
definePageMeta({
  layout: 'footer-only',
  middleware: ['login-not-required']
})

/* state */
const { $api } = useNuxtApp()
const authStore = useAuthStore()
const { isRequriedInit } = storeToRefs(authStore)
const { openAlertModal, openConfirmModal } = useModalStore()
const router = useRouter()
const userId = ref<string>('')
const pwd = ref<string>('')
const isHide = ref<boolean>(true)
const hasError = ref<boolean>(false)
const openModalType = ref<
  typeof TYPE_FIND_ID | typeof TYPE_FIND_PWD | typeof TYPE_UNLOCK | ''
>('')

const phase = ref<typeof DEFAULT_PHASE | typeof PWD_PHASE | typeof TERMS_PHASE>(
  DEFAULT_PHASE
)
const pwdToken = ref<string>('')

/* computed */
const disabled = computed(
  () => userId.value.length === 0 || pwd.value.length === 0
)
const pwdIconName = computed(() => (isHide.value ? 'eye_closed' : 'eye'))
const pwdType = computed(() => (isHide.value ? 'password' : 'text'))
const options = [
  { type: 'join', text: '회원가입' },
  { type: TYPE_FIND_ID, text: '아이디 찾기' },
  { type: TYPE_FIND_PWD, text: '비밀번호 찾기' }
]
const isOpenFindId = computed(() => openModalType.value === TYPE_FIND_ID)
const isOpenFindPwd = computed(() => openModalType.value === TYPE_FIND_PWD)
const isOpenUnlock = computed(() => openModalType.value === TYPE_UNLOCK)

watch([userId, pwd], () => {
  hasError.value = false
})

watch(pwdToken, (val) => {
  if (val.length > 0) {
    phase.value = PWD_PHASE
  } else {
    phase.value = DEFAULT_PHASE
  }
})

const closeModal = () => {
  openModalType.value = ''
}
const handleLogin = async () => {
  const rvCode = await authStore.login({
    userId: userId.value,
    password: pwd.value
  })
  switch (rvCode) {
    case 0:
      hasError.value = false
      return router.push(isRequriedInit.value ? '/init' : '/')

    case 90033: // 로그인 제한
      openAlertModal({
        className: 'alert_login_limit',
        title: '로그인 제한',
        content:
          '잘못된 아이디 또는 비밀번호 입력으로\n하루에 가능한 로그인 시도 횟수를 초과했습니다.\n다음날 다시 시도하거나,\n등록된 이메일 주소로 제한을 해제할 수 있습니다',
        confirmText: '등록된 이메일로 제한 해제하기',
        callback: () => {
          handleOption(TYPE_UNLOCK)
        }
      })
      break
    case 90035: // 탈퇴 신청 중 회원
      openConfirmModal({
        className: 'confirm_delete_account',
        title: '탈퇴 신청 취소',
        content:
          '해당 계정은 탈퇴 처리 중입니다.\n지금 로그인하시면 탈퇴 신청이 취소됩니다.\n탈퇴를 취소하고 계정을 정상화하겠습니까?',
        closeText: '닫기',
        confirmText: '탈퇴 취소하기',
        callback: async () => {
          const resp = await $api.account.cancelDeleteAccount({
            userId: userId.value,
            password: pwd.value
          })
          console.log(resp)
          if (resp && resp.code === 0) {
            handleLogin()
          } else {
            openAlertModal({
              content: '탈퇴 신청 취소에 실패했습니다.'
            })
          }
        }
      })
      break
    default:
      hasError.value = true
      break
  }
}
const handleOption = (type: string) => {
  switch (type) {
    case 'join':
      router.push({ name: 'join' })
      break
    case TYPE_FIND_ID:
    case TYPE_FIND_PWD:
    case TYPE_UNLOCK:
      openModalType.value = type

      break
  }
}
</script>

<style scoped lang="scss">
.form_login {
  @include desktop {
    .row_form {
      flex-direction: row;
      align-items: center;
      .input_form {
        margin-left: auto;
        width: calc(100% - 70px);
      }
    }
  }
  .txt_err {
    display: flex;
    margin-top: 24px;
    column-gap: 6px;
    justify-content: center;
    font-size: 16px;

    > .icon_fail {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }
  }
  .list_option {
    display: flex;
    margin-top: 32px;
    gap: 0 16px;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.64px;
    .item_option {
      position: relative;
      cursor: pointer;
      + .item_option {
        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          top: 8px;
          left: -8px;
          width: 1px;
          height: 8px;
          background-color: $gray1;
          cursor: default;
        }
      }
    }
  }
  @include tablet {
    .txt_err {
      justify-content: normal;
      > .icon_fail {
        margin-top: 1px;
      }
    }
    .list_option {
      margin-top: 24px;
    }
  }
  @include mobile {
    .txt_err {
      margin-top: 16px;
      align-items: normal;
      font-size: 15px;
    }
  }
}
</style>
